import _, { includes } from "underscore";
import { AnalyticsBrowser } from "@segment/analytics-next";
import Constants from "./Constants";
import { Capacitor } from "@capacitor/core";
import Segment from "../plugins/Segment";
import { jitsuAnalytics } from "@jitsu/js";

export interface SegmentIdentify {
  email?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  store_id?: string;
  store_name?: string;
  id?: string;
  user_id?: string;
  customer_id?: string;
  lead_id?: string;
}

export interface SegmentCheckoutFlowSignupStartedProps {
  first_session: boolean;
}

export interface SegmentCheckoutFlowSignupStepViewedProps {
  step: string;
}

export class SegmentProductCategories {
  static MEAL_PLANS = "Meal Plans";
  static LIFESTYLE_MEALS = "Lifestyle Meals";
  static ATHLETE_MEALS = "Athlete Meals";
}

export default class SegmentUtils {
  static latestPage: any = null;

  static eventIdentifiers: any = [];

  static analytics: AnalyticsBrowser;

  static jitsu: any;

  static load() {
    const win: any = window;

    /*if (!Capacitor.isNativePlatform()) {
      const apiKey =
        win?.location?.href.includes("vercel.app") ||
        win?.location?.href.includes("projectleannation.com")
          ? Constants.SEGMENT_API_KEY
          : Constants.SEGMENT_API_KEY_DEV;

      const analytics = AnalyticsBrowser.load({ writeKey: apiKey });

      this.analytics = analytics;
    }*/

    const jitsu = jitsuAnalytics({
      host: "https://data.projectleannation.com",
      //For browser, write key is not required, individual tracking host is enough
      //For nodejs, use a server write key from JS settings
      writeKey: "",
    });

    this.jitsu = jitsu;
  }

  /**
   * Converts URLs to readable page names
   *
   * @param location
   * @returns
   */
  private static pathToPageName(location: any = {}) {
    const path = location?.pathname;

    //console.log(path);

    if (path === "/") {
      return {
        category: "App",
        name: "Home",
      };
    }

    if (path === "/start") {
      return {
        category: "Checkout",
        name: "Start",
      };
    }

    if (path === "/start/locations") {
      return {
        category: "Checkout",
        name: "Locations",
      };
    }

    if (path === "/start/plans") {
      return {
        category: "Checkout",
        name: "Plans",
      };
    }

    if (path === "/start/box") {
      return {
        category: "Checkout",
        name: "Menu",
      };
    }

    if (path === "/start/checkout") {
      return {
        category: "Checkout",
        name: "Summary",
      };
    }

    if (path === "/start/checkout/complete") {
      return {
        category: "Checkout",
        name: "Completion",
      };
    }

    if (path === "/manage/home" || path == "/manage") {
      return {
        category: "Manage",
        name: "Home",
      };
    }

    if (
      path === "/manage/home/meals" ||
      path == "/manage/meals" ||
      path == "/manage/menu"
    ) {
      return {
        category: "Manage",
        name: "Select Meals",
      };
    }

    if (path === "/manage/home/referrals") {
      return {
        category: "Manage",
        name: "Refer Friends & Family",
      };
    }

    if (path === "/manage/settings/referrals") {
      return {
        category: "Manage",
        name: "Refer Friends & Family",
      };
    }

    if (path === "/manage/settings") {
      return {
        category: "Manage",
        name: "Account Settings",
      };
    }

    if (path === "/schedule") {
      return {
        category: "Schedule",
        name: "Home",
      };
    }

    if (
      path?.includes("/schedule/") &&
      (path?.includes("/nc-consult") || path?.includes("/ec-consult"))
    ) {
      return {
        category: "Schedule",
        name: "Availability",
      };
    }

    if (
      path?.includes("/schedule/") &&
      (path?.includes("/nc-consult") || path?.includes("/ec-consult")) &&
      !path?.includes("/nc-consult/") &&
      !path.includes("/ec-consult/")
    ) {
      return {
        category: "Schedule",
        name: "Availability",
      };
    }

    if (
      path?.includes("/schedule/") &&
      (path?.includes("/nc-consult/") || path.includes("/ec-consult/"))
    ) {
      return {
        category: "Schedule",
        name: "Details",
      };
    }

    return {
      category: "App",
      name: path,
    };
  }

  /**
   * Log segment page events
   *
   * @param location
   * @param properties
   * @returns
   */
  static webPage(location: any = {}, properties: any = {}) {
    if (location?.pathname === this.latestPage) {
      return;
    }

    try {
      this.latestPage = location?.pathname;

      const { category, name }: any = this.pathToPageName(location);

      if (Capacitor.isNativePlatform()) {
        Segment.screen({ name, category, properties });

        if (Capacitor.getPlatform() == "android") {
          this.jitsu.page(`${category ? `${category} - ` : ""}${name}`, {
            ...properties,
            path: location?.pathname,
            url: location?.href,
            title: location?.title,
            search: location?.search,
          });
        }
      } else {
        /*if (!this?.analytics) {
          console.error("Segment not initialized");

          return;
        }*/

        //console.log("Segment Page", category, name, properties);

        //this.analytics?.page(category, name, properties);
        this.jitsu.page(`${category ? `${category} - ` : ""}${name}`, {
          ...properties,
          path: location?.pathname,
          url: location?.href,
          title: location?.title,
          search: location?.search,
        });

        const win: any = window;

        if (typeof win?.fbq == "function") {
          win?.fbq("track", "PageView");
        }

        if (typeof win?.gtag == "function") {
          win.gtag("config", "G-5E2RB13NYH", {
            page_title: win?.document?.title,
            page_path: win?.location?.pathname,
            page_location: win?.location?.href,
          });
        }

        if (typeof win?.Bird?.tracker?.web?.pageViewed == "function") {
          setTimeout(() => {
            let birdPayload = {
              url: win?.location?.href,
              title: document?.title,
              search: win?.location?.search,
              referrer: document.referrer,
              path: location?.pathname,
            };

            win.Bird.tracker.web.pageViewed(birdPayload);

            //console.log("Bird Page View", birdPayload); // TODO: debug
          }, 0);
        }
      }

      setTimeout(() => {
        this.latestPage = null;
      }, 10);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Calls to identify the user
   *
   * @param properties
   * @returns
   */
  static identify(properties: SegmentIdentify = {}) {
    const win: any = window;

    if (!properties) {
      return;
    }

    try {
      const vals = _.omit(properties, ["user_id", "id"]);

      if (properties?.id) {
        properties.user_id = properties?.id;

        if (Capacitor.isNativePlatform()) {
          if (Capacitor.getPlatform() == "ios") {
            Segment.identify({
              id: properties.user_id ? properties?.user_id : "",
              traits: properties,
            }).then(
              (data: any) => {
                console.log("SEGMENT NATIVE IDENTIFY SUCCESS", data.success);
              },
              (e) => {
                console.error("SEGMENT IDENTIFY ERROR", e);
              }
            );
          }

          // TODO: revisit with native solution
          if (Capacitor.getPlatform() == "android") {
            console.log("SEGMENT ANDROID IDENTIFY");

            this.jitsu?.identify(properties?.id, properties);
          }

          return;
        } else {
          /*if (!this.analytics) {
            console.error("Segment not initialized");

            return;
          }*/

          //this.analytics?.identify(properties?.id, properties);

          this.jitsu.identify(properties?.id, properties);
        }

        if (win?.posthog) {
          win?.posthog?.identify(properties.user_id, vals);

          if (win?.location?.href?.includes("localhost")) {
            console.log("Segment Identify", properties?.id, properties);
          }
        }
      } else {
        if (Capacitor.isNativePlatform()) {
          console.log("SEGMENT NATIVE 2");

          Segment.identify({ traits: properties, id: "" }).then((data: any) => {
            console.log("SEGMENT NATIVE IDENTIFY SUCCESS", data.success);
          });

          // TODO: revisit with native solution
          if (Capacitor.getPlatform() == "android") {
            this.jitsu.identify(properties);
          }

          return;
        } else {
          /*if (!this.analytics) {
            console.error("Segment not initialized");

            return;
          }*/

          //this.analytics?.identify(properties);
          this.jitsu.identify(properties);
        }

        if (win?.location?.href?.includes("localhost")) {
          console.log("Segment Identify", properties);
        }
      }

      if (!Capacitor.isNativePlatform()) {
        if (typeof win?.gtag == "function") {
          win.gtag("config", "G-5E2RB13NYH", {
            page_title: win?.document?.title,
            page_path: win?.location?.pathname,
            page_location: win?.location?.href,
            user_id: properties?.user_id,
            email: properties?.email ? properties?.email : "",
            store_id: properties?.store_id ? properties?.store_id : "",
          });
        }

        if (typeof win?.fbq == "function") {
          win?.fbq("init", "399238238134004", {
            em: properties?.email,
            ph: properties?.phone,
            external_id: properties?.user_id,
          });
        }
      }
    } catch (e) {
      console.error(e);
    }

    if (typeof win?.Bird?.contact?.identify == "function") {
      win.Bird.contact.identify({
        strategy: "Visitor",
        identifier: {
          key: "emailaddress",
          value: properties?.email,
        },
      });

      //console.log("Bird Identify", properties?.email); // TODO: debug
    }
  }

  /**
   * Tracks events
   *
   * @param event
   * @param properties
   * @returns
   */
  static track(
    event: string,
    properties: any = {},
    idempotencyKey: string = ""
  ) {
    const win: any = window;

    if (Capacitor.isNativePlatform()) {
      Segment.track({ event, properties }).then((data: any) => {
        console.log("SEGMENT NATIVE SUCCESS", data.success);
      });

      // TODO: revisit with native solution
      if (Capacitor.getPlatform() == "android") {
        this.jitsu.track(event, properties);
      }

      return;
    }

    try {
      /*if (!this.analytics) {
        console.error("Segment not initialized");

        return;
      }*/

      if (this.eventIdentifiers.includes(idempotencyKey)) {
        //console.log('Skipping Duplicate Segment Event', event, idempotencyKey);

        return;
      }

      if (idempotencyKey) {
        this.eventIdentifiers.push(idempotencyKey);

        setTimeout(() => {
          this.eventIdentifiers = this.eventIdentifiers.filter(
            (identifier: string) => identifier !== idempotencyKey
          );
        }, 2);
      }

      if (win?.location?.href?.includes("localhost")) {
        console.log("Segment Event:", event, properties, idempotencyKey);
      }

      //this.analytics?.track(event, properties);
      this.jitsu.track(event, properties);

      if (typeof win?.fbq == "function") {
        win?.fbq("trackCustom", event, properties);

        if (this.FB_PIXEL_MAPPING?.hasOwnProperty(event)) {
          let mapping = this.FB_PIXEL_MAPPING[event];

          win.fbq("track", mapping, properties);
        }
      }

      if (typeof win?.gtag == "function") {
        if (this.GA4_MAPPING?.hasOwnProperty(event)) {
          let ga4Props = this.mapPropertiesToGA4(event, properties);

          //console.log("GA4 Props", this.GA4_MAPPING[event].event, ga4Props);

          win.gtag("event", this.GA4_MAPPING[event].event, ga4Props);
        }
      }

      if (typeof win?.Bird?.tracker?.track == "function") {
        win.Bird.tracker.track(event, properties);

        if (
          event == "Product Clicked" &&
          properties?.category == "Meal Plans"
        ) {
          win.Bird.tracker.ecommerce.cartViewed({
            product_ids: [properties?.product_id],
            currency: "USD",
            product_names: [properties?.name],
            product_skus: [properties?.sku],
            total_price: properties?.price,
            total_quantity: properties?.quantity,
          });

          //console.log("Bird Cart Viewed", properties); // TODO: debug
        }

        //console.log("Bird Event", event, properties); // TODO: debug
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Maps properties to GA4
   *
   * @param event
   * @param properties
   * @returns
   */
  static mapPropertiesToGA4(event: string, properties: any = {}) {
    if (!this.GA4_MAPPING?.hasOwnProperty(event)) {
      return properties;
    }

    let mapping = this.GA4_MAPPING[event];

    let mappedProperties: any = {};

    for (let key in mapping.properties) {
      let mappedKey = mapping.properties[key];

      if (properties.hasOwnProperty(key)) {
        mappedProperties[mappedKey] = properties[key];
      }
    }

    for (let key in mapping.arrayProperties) {
      let arrayProperty = mapping.arrayProperties[key];

      if (properties.hasOwnProperty(key)) {
        let array = properties[key];

        if (Array.isArray(array)) {
          let mappedArray: any = [];

          array.forEach((item: any, index: number) => {
            let mappedItem: any = {};

            for (let itemKey in arrayProperty.properties) {
              let mappedItemKey = arrayProperty.properties[itemKey];

              if (item.hasOwnProperty(itemKey)) {
                mappedItem[mappedItemKey] = item[itemKey];
              }
            }

            mappedArray.push(mappedItem);
          });

          console.log(mappedArray);

          mappedProperties[arrayProperty.arrayProperty] = mappedArray;
        }
      }
    }

    if (mapping.defaultProperties) {
      mappedProperties = {
        ...mappedProperties,
        ...mapping.defaultProperties,
      };
    }

    return mappedProperties;
  }

  static FB_PIXEL_MAPPING: any = {
    "Product Added": "AddToCart",
    "Payment Info Entered": "AddPaymentInfo",
    "Checkout Started": "InitiateCheckout",
    "Signup Started": "Lead",
    "Signup Completed": "CompleteRegistration",
    "Franchise Lead Created": "SubmitApplication",
  };

  static GA4_MAPPING: any = {
    "Signup Started": {
      event: "generate_lead",
      defaultProperties: {
        currency: "USD",
        value: 68.11,
      },
      properties: {},
    },
    "Product List Viewed": {
      event: "view_item_list",
      properties: {
        category: "item_list_name",
        list_id: "items_list_id",
      },
      arrayProperties: {
        products: {
          arrayProperty: "items",
          properties: {
            product_id: "item_id",
            name: "item_name",
            position: "index",
            brand: "item_brand",
            price: "price",
            quantity: "quantity",
            google_location_id: "location_id",
            category: "item_variant",
            category1: "item_category",
            category2: "item_category2",
            category3: "item_category3",
          },
        },
      },
    },
    "Checkout Started": {
      event: "begin_checkout",
      properties: {
        value: "value",
      },
      defaultProperties: {
        currency: "USD",
      },
      arrayProperties: {
        products: {
          arrayProperty: "items",
          properties: {
            product_id: "item_id",
            name: "item_name",
            brand: "item_brand",
            quantity: "quantity",
            category: "item_variant",
            category1: "item_category",
            category2: "item_category2",
            category3: "item_category3",
            price: "price",
            google_location_id: "location_id",
          },
        },
      },
    },
    "Signup Completed": {
      event: "purchase",
      properties: {
        value: "value",
        coupon_code: "coupon",
      },
      defaultProperties: {
        currency: "USD",
      },
      arrayProperties: {
        products: {
          arrayProperty: "items",
          properties: {
            product_id: "item_id",
            name: "item_name",
            brand: "item_brand",
            quantity: "quantity",
            category: "item_variant",
            category1: "item_category",
            category2: "item_category2",
            category3: "item_category3",
            price: "price",
            google_location_id: "location_id",
          },
        },
      },
    },
    "Cart Viewed": {
      event: "view_cart",
      arrayProperties: {
        products: {
          arrayProperty: "items",
          properties: {
            product_id: "item_id",
            name: "item_name",
            brand: "item_brand",
            quantity: "quantity",
            category: "item_variant",
            category1: "item_category",
            category2: "item_category2",
            category3: "item_category3",
            price: "price",
            google_location_id: "location_id",
          },
        },
      },
    },
  };
}
