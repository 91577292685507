import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import {
  IonApp,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonLoading,
  IonMenu,
  IonMenuButton,
  IonPage,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
  setupIonicReact,
  useIonLoading,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ellipse, square, triangle } from "ionicons/icons";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "../../theme/variables.css";
import "../../theme/pln/splitpane.css";
import Menu from "../../components/Menu";
import Start from "../../pages/web/checkout/Start/Start";
import Plans from "../../pages/web/checkout/Plans/Plans";
import PlansV2 from "../../pages/web/checkout/Plans/PlansV2";
import Stores from "../../pages/web/checkout/Stores/Stores";
import Box from "../../pages/web/checkout/Box/Box";
import Checkout from "../../pages/web/checkout/Checkout/Checkout";
import { useCallback, useEffect, useMemo, useState } from "react";
import API from "../../lib/API";
import StorageUtils from "../../lib/StorageUtils";
import PubSub from "../../lib/PubSub";
import Event from "../../lib/Event";
import CheckoutComplete from "../../pages/web/checkout/CheckoutComplete/CheckoutComplete";
import ToastProvider from "../../providers/ToastProvider/ToastProvider";
import SegmentUtils, {
  SegmentCheckoutFlowSignupStartedProps,
  SegmentIdentify,
} from "../../lib/SegmentUtils";
import StringUtils from "../../lib/StringUtils";
import moment, { Moment } from "moment";
import Page404 from "../../pages/web/authentication/404/Page404";
import SEOHeaders from "../../components/SEOHeaders";
import _ from "underscore";
import PaymentUtils from "../../lib/PaymentUtils";
import BoxNoSelection from "../../pages/web/checkout/Box/BoxNoSelection";

setupIonicReact();

const PathNameTitles: any = {
  "/start": "Sign Up",
  "/start/locations": "Choose A Location",
  "/start/plans": "Choose A Plan",
  "/start/box": "Select Meals",
  "/start/checkout": "Checkout",
  "/start/checkout/complete": "Welcome to PLN",
};

const WebAppCheckout: React.FC = () => {
  console.log("WEB APP");

  const [leadLoading, setLeadLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const [prevLoc, setPrevLoc] = useState<any>(null);

  const identifySegmentLead = (lead: any) => {
    let segmentProps: SegmentIdentify = {};

    if (lead?.email) {
      segmentProps.email = lead?.email;
    }

    if (lead?.phone) {
      segmentProps.phone = lead?.phone;
    }

    if (lead?.name) {
      segmentProps.first_name = StringUtils.getFirstName(lead?.name);
      segmentProps.last_name = StringUtils.getLastName(lead?.name);
    }

    if (lead?.userID) {
      segmentProps.id = lead?.userID;
    }

    if (lead?.storeID) {
      segmentProps.store_id = lead?.storeID;
    }

    segmentProps.lead_id = lead?._id;

    SegmentUtils.identify(segmentProps);
  };

  useEffect(() => {
    if (
      !location ||
      (prevLoc?.pathname == location?.pathname &&
        location?.pathname?.includes("/start"))
    ) {
      return;
    }

    SegmentUtils.webPage(location);

    setPrevLoc(location);
  }, [location?.pathname]);

  let loadStripeClient = async (email: string) => {
    PaymentUtils.loadStripeClient(email);
  };

  useEffect(() => {
    if (leadLoading) {
      return;
    }

    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    let leadRestore = urlParams.get("leadRestore");
    let email = urlParams.get("email");
    let phone = urlParams.get("phone");
    let promo = urlParams.get("promo");
    let firstBillDate = urlParams.get("fbd");
    let storeID = urlParams.get("storeID");
    let overrideDefaultCoupon = urlParams.get("odc");

    if (overrideDefaultCoupon == "1") {
      console.log("OVERRIDE DEFAULT COUPON");

      StorageUtils.set(
        "checkout_overrideDefaultCoupon",
        moment().toISOString()
      );
    }

    StorageUtils.get("checkout_lead").then((lead: any) => {
      if (lead && !leadRestore) {
        const props: SegmentCheckoutFlowSignupStartedProps = {
          first_session: lead?.cartState?.step ? false : true,
        };

        SegmentUtils.track("Signup Started", props);

        identifySegmentLead(lead);

        if (lead?.email) {
          loadStripeClient(lead?.email);
        }
      }
    });

    if (leadRestore) {
      setLeadLoading(true);

      API.getCartState(urlParams.get("leadRestore"))
        .then(
          async (data: any) => {
            console.log(data.data.data);

            let lead = data?.data?.data?.lead;

            const props: SegmentCheckoutFlowSignupStartedProps = {
              first_session: lead?.cartState?.step ? false : true,
            };

            SegmentUtils.track("Signup Started", props);

            await StorageUtils.set("checkout_lead", lead);

            identifySegmentLead(lead);

            await StorageUtils.set("checkout_email", lead?.email);
            await StorageUtils.set(
              "checkout_phone",
              lead?.phone ? lead?.phone : ""
            );

            // Load an existing coupon if no new coupon is applied
            if (data?.data?.data?.lead?.coupon && !promo) {
              await StorageUtils.set(
                "checkout_coupon",
                data?.data?.data?.lead?.coupon
              );

              PubSub.publish(Event.CHECKOUT.COUPON_UPDATED, {
                coupon: data?.data?.data?.lead?.coupon,
              });

              SegmentUtils.track("Coupon Applied", {
                cart_id:
                  (lead?.userID ? lead?.userID : lead?._id) +
                  "_cart_" +
                  moment().format("YYYY-MM-DD"),
                coupon_id: data?.data?.data?.lead?.coupon?.code,
                coupon_name: data?.data?.data?.lead?.coupon?.name,
                coupon_terms: data?.data?.data?.lead?.coupon?.terms
                  ? data?.data?.data?.lead?.coupon?.terms
                  : "",
                type: "discount",
              });
            }

            if (data.data.data?.store) {
              await StorageUtils.set("checkout_store", data.data.data.store);
            }

            if (data.data.data?.plan) {
              await StorageUtils.set("checkout_plan", data.data.data.plan);
            }

            if (data.data.data?.selectedMeals) {
              await StorageUtils.set(
                "checkout_meals",
                data.data.data.selectedMeals
              );
            }

            if (lead?.email) {
              loadStripeClient(lead?.email);
            }

            // Redirect to locations page
            if (!lead?.storeID) {
              if (window.location.href?.includes("/start/plans")) {
                PubSub.publish(Event.CHECKOUT.LEAD_RESTORED, lead);
              } else {
                setTimeout(() => {
                  history.push("/start/plans");
                }, 0);
              }
            } else {
              if (!lead?.cartState?.planID) {
                // Redirect to plans page
                if (window.location.href?.includes("/start/plans")) {
                  PubSub.publish(Event.CHECKOUT.LEAD_RESTORED, lead);
                } else {
                  setTimeout(() => {
                    history.push("/start/plans");
                  }, 0);
                }
              } else if (
                lead?.cartState?.step == "menu" &&
                lead?.cartState?.planID
              ) {
                // Redirect to box page
                if (window.location.href?.includes("/start/box")) {
                  PubSub.publish(Event.CHECKOUT.LEAD_RESTORED, lead);
                } else {
                  setTimeout(() => {
                    history.push("/start/box");
                  }, 0);
                }
              } else if (lead?.cartState?.step == "checkout") {
                // TODO: check for valid plan and full box here

                // Redirect to checkout page
                if (window.location.href?.includes("/start/checkout")) {
                  PubSub.publish(Event.CHECKOUT.LEAD_RESTORED, lead);
                } else {
                  setTimeout(() => {
                    history.push("/start/checkout");
                  }, 0);
                }
              } else {
                // Default to locations
                if (window.location.href?.includes("/start/plans")) {
                  PubSub.publish(Event.CHECKOUT.LEAD_RESTORED, lead);
                } else {
                  setTimeout(() => {
                    history.push("/start/plans");
                  }, 0);
                }
              }
            }
          },
          (e: any) => {
            console.error(e);

            // Redirect to start on error
            if (window.location.pathname != "/start") {
              setTimeout(() => {
                history.push("/start");
              }, 0);
            } else {
            }
          }
        )
        .finally(() => {
          setLeadLoading(false);
        });
    } else {
      if (email) {
        StorageUtils.set("checkout_email", email);
      }

      if (phone) {
        StorageUtils.set("checkout_phone", phone);
      }

      if (storeID) {
        API.getStoreByID(storeID).then((data: any) => {
          StorageUtils.set("checkout_store", data?.data?.data?.store);
        });
      }
    }

    if (firstBillDate) {
      const mom: Moment = moment(firstBillDate);

      console.log(mom);

      if (mom?.day() == 6 && mom?.isValid()) {
        StorageUtils.set("checkout_first_bill_date", mom.format("YYYY-MM-DD"));
      }
    }

    if (promo) {
      console.log(promo);

      API.getCouponByCode(promo?.toUpperCase()).then(
        async (data: any) => {
          const coupon = data?.data?.data?.coupon;

          if (coupon) {
            SegmentUtils.track("Coupon Applied", {
              coupon_id: coupon?.code,
              coupon_name: coupon?.name,
              coupon_terms: coupon?.terms ? coupon?.terms : "",
              type: coupon?.code?.indexOf("M-") == 0 ? "discount" : "referral",
            });

            if (coupon?.code?.indexOf("M-") == 0) {
              StorageUtils.set("checkout_referral", coupon);

              const existingCoupon = await StorageUtils.get("checkout_coupon");

              if (!existingCoupon) {
                PubSub.publish(Event.CHECKOUT.COUPON_UPDATED, { coupon });
              }
            } else {
              StorageUtils.set("checkout_coupon", coupon);
              PubSub.publish(Event.CHECKOUT.COUPON_UPDATED, { coupon });
            }
          }
        },
        (e: any) => {
          console.error(e);
        }
      );
    }
  }, []);

  const toastProvider = useMemo(() => {
    return <ToastProvider />;
  }, []);

  const seo = useCallback(() => {
    console.log(location?.pathname);

    const title = PathNameTitles[location.pathname];

    if (!title) {
      return null;
    }

    console.log(title);

    return <SEOHeaders title={title}></SEOHeaders>;
  }, [location?.pathname]);

  return (
    <>
      {seo()}
      <IonRouterOutlet animated={false} id="main">
        <Route path="/start" component={Start} exact={true}></Route>
        <Route path="/start/locations" component={Stores} exact={true}></Route>
        <Route path="/start/plans" component={PlansV2} exact={true}></Route>
        {/*<Route path="/start/plansv2" component={PlansV2} exact={true}></Route> */}
        <Route
          path="/start/box"
          component={BoxNoSelection}
          exact={true}
        ></Route>
        <Redirect from="/start/boxv2" to="/start/box" exact={true}></Redirect>
        <Route path="/start/checkout" component={Checkout} exact={true}></Route>
        <Route
          path="/start/checkout/complete"
          component={CheckoutComplete}
          exact={true}
        ></Route>
        <Route component={Page404}></Route>
      </IonRouterOutlet>
      <IonLoading
        isOpen={leadLoading}
        mode="ios"
        message={"Hang tight, we're loading your cart!"}
        animated={true}
        spinner={"dots"}
      />
      {toastProvider}
    </>
  );
};

export default WebAppCheckout;
